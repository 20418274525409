import React, { useState,useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

function Contact(props) {
    const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(error => {
        console.error('Error attempting to play video:', error);
      });
    }
  }, []);

    return (
        <>
            <div id="sc_contact">
                <div id="bg_contact">
                    <div className="container text-center">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 text-left">
                            <video ref={videoRef} controls autoPlay muted style={{ width: '100%' }}>
                            <source src="/media/video.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                            </video>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contact;