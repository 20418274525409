import React from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faTelegram, faTwitter } from '@fortawesome/free-brands-svg-icons';
const Footer = (props) => {

    const location = useLocation();

    let checkHome = 'home py-3 my-4';

    if (location.pathname != '/') {
        checkHome = 'viewPage py-3 my-4';
    }
    return (
        <>
            <footer className={checkHome}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6 ktc'>
                            <a href='https://t.me/FFAc9' target="_blank"><FontAwesomeIcon icon={faTelegram} /></a>
                            <a href='https://twitter.com/fruitforest_ffa' target="_blank"><FontAwesomeIcon icon={faTwitter} /></a>
                            <a href='mailto:Adventure@fruitforestffa.com' target="_blank"><FontAwesomeIcon icon={faEnvelope} /></a>
                        </div>
                        <div className='col-md-6'>
                            <p className="copyright">© 2024 - Fruit Forest Adventure</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;